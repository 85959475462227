















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ParityCountryFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-country-filter.vue';
import ParityPickUpCityFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-pick-up-city-filter.vue';
import ParityDataSourcesFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-data-sources-filter.vue';
import PARITY_FILTER from '@/modules/cars/modules/parity/constants/parity-filter.constant';
import FilterOptions from '@/modules/cars/components/cars-filters/filter-options.vue';
import ParityFiltersService, { ParityFiltersServiceS } from '@/modules/cars/modules/parity/parity-filters.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

@Component({
    components: {
        ParityCountryFilter,
        ParityPickUpCityFilter,
        ParityDataSourcesFilter,
        FilterOptions,
    },
})
export default class BrokersActions extends Vue {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;

    get activeFilters(): PARITY_FILTER[] {
        return Object.entries(PARITY_FILTER).map(([key, value]) => value);
    }

    isCountry(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.COUNTRY;
    }

    isPickup(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.PICKUP;
    }

    isDataSourcesFilter(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.DATA_SOURCES && this.carsSharedService.isBrokerMode;
    }

    onFiltersSave() {
        this.parityFiltersService.saveUserFilterValues();
    }

    onFiltersClear() {
        this.parityFiltersService.clearUserFilterValues();
    }
}
