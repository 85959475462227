import { render, staticRenderFns } from "./brokers-table.vue?vue&type=template&id=25b1eb8d&scoped=true&"
import script from "./brokers-table.vue?vue&type=script&lang=ts&"
export * from "./brokers-table.vue?vue&type=script&lang=ts&"
import style0 from "./brokers-table.vue?vue&type=style&index=0&id=25b1eb8d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b1eb8d",
  null
  
)

export default component.exports