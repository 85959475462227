




















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import ParityFiltersService, { ParityFiltersServiceS } from '@/modules/cars/modules/parity/parity-filters.service';
import BrokersTableInfo from '../components/brokers/brokers-table-info.vue';
import KpiTabs from '../components/brokers/kpi-tabs.vue';
import BrokersTable from '../components/brokers/brokers-table.vue';
import PerformanceBrokers from '../components/brokers/performance-brokers.vue';
import BrokersActions from '../components/brokers/brokers-actions.vue';

@Component({
    components: {
        PageWrapper,
        CarHeader,
        KpiTabs,
        BrokersTableInfo,
        BrokersTable,
        PerformanceBrokers,
        BrokersActions,
    },
})
export default class BrokersPage extends Vue {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;

    mounted() {
        // Redirect to broker page if we have stored filter value.
        // Use $nextTick to ensure that parityFiltersService constructor was ready.
        this.$nextTick(() => {
            if (this.parityFiltersService.storeState.settings.provider) {
                this.$router.push({ name: 'parity-provider', params: { provider: this.parityFiltersService.storeState.settings.provider } });
            }
        });
        // if (!this.userService.currentCompany) {
        //     return;
        // }
        // if (this.userService.currentCompany.toLowerCase() === 'rentalcars') {
        //     this.$router.push('/');
        // }
    }
}
