const BROKERS_COLORS = [
    '#FED41D',
    '#1BBF83',
    '#E98583',
    '#011dfe',
    '#5cdbfd',
    '#d604fa',
    '#181717',
    '#7c85fc',
    '#cafc03',
    '#e38608',
];

export default BROKERS_COLORS;
