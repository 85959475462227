const MAP_STYLES: {}[] = [
    // Styles for Silver theme
    // {
    //     elementType: 'geometry',
    //     stylers: [{
    //         color: '#f5f5f5',
    //     }],
    // },
    // {
    //     elementType: 'labels.icon',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     elementType: 'labels.text.fill',
    //     stylers: [{
    //         color: '#616161',
    //     }],
    // },
    // {
    //     elementType: 'labels.text.stroke',
    //     stylers: [{
    //         color: '#f5f5f5',
    //     }],
    // },
    // {
    //     featureType: 'administrative',
    //     elementType: 'geometry',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     featureType: 'administrative.land_parcel',
    //     elementType: 'labels',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     featureType: 'administrative.land_parcel',
    //     elementType: 'labels.text.fill',
    //     stylers: [{
    //         color: '#bdbdbd',
    //     }],
    // },
    // {
    //     featureType: 'poi',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     featureType: 'poi',
    //     elementType: 'geometry',
    //     stylers: [{
    //         color: '#eeeeee',
    //     }],
    // },
    // {
    //     featureType: 'poi',
    //     elementType: 'labels.text',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     featureType: 'poi',
    //     elementType: 'labels.text.fill',
    //     stylers: [{
    //         color: '#757575',
    //     }],
    // },
    // {
    //     featureType: 'poi.park',
    //     elementType: 'geometry',
    //     stylers: [{
    //         color: '#e5e5e5',
    //     }],
    // },
    // {
    //     featureType: 'poi.park',
    //     elementType: 'labels.text.fill',
    //     stylers: [{
    //         color: '#9e9e9e',
    //     }],
    // },
    // {
    //     featureType: 'road',
    //     elementType: 'geometry',
    //     stylers: [{
    //         color: '#ffffff',
    //     }],
    // },
    // {
    //     featureType: 'road',
    //     elementType: 'labels.icon',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     featureType: 'road.arterial',
    //     elementType: 'labels',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     featureType: 'road.arterial',
    //     elementType: 'labels.text.fill',
    //     stylers: [{
    //         color: '#757575',
    //     }],
    // },
    // {
    //     featureType: 'road.highway',
    //     elementType: 'geometry',
    //     stylers: [{
    //         color: '#dadada',
    //     }],
    // },
    // {
    //     featureType: 'road.highway',
    //     elementType: 'labels',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     featureType: 'road.highway',
    //     elementType: 'labels.text.fill',
    //     stylers: [{
    //         color: '#616161',
    //     }],
    // },
    // {
    //     featureType: 'road.local',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     featureType: 'road.local',
    //     elementType: 'labels',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     featureType: 'road.local',
    //     elementType: 'labels.text.fill',
    //     stylers: [{
    //         color: '#9e9e9e',
    //     }],
    // },
    // {
    //     featureType: 'transit',
    //     stylers: [{
    //         visibility: 'off',
    //     }],
    // },
    // {
    //     featureType: 'transit.line',
    //     elementType: 'geometry',
    //     stylers: [{
    //         color: '#e5e5e5',
    //     }],
    // },
    // {
    //     featureType: 'transit.station',
    //     elementType: 'geometry',
    //     stylers: [{
    //         color: '#eeeeee',
    //     }],
    // },
    // {
    //     featureType: 'water',
    //     elementType: 'geometry',
    //     stylers: [{
    //         color: '#c9c9c9',
    //     }],
    // },
    // {
    //     featureType: 'water',
    //     elementType: 'labels.text.fill',
    //     stylers: [{
    //         color: '#9e9e9e',
    //     }],
    // },
];

export default MAP_STYLES;
